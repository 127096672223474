import axios from 'axios';

import { User } from '../../components/shared/model/user/UserTypes';
import axiosRequestErrorHandler from '../utils/axioxRequestUtils';

const getUser = async (serviceUrl: string, apiKey: string, token: string, email: string): Promise<User> => {
  try {
    const response = await axios.post<User>(
      `${serviceUrl}/user`,
      { email },
      {
        headers: {
          'x-api-key': apiKey,
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    return axiosRequestErrorHandler(error);
  }
};

export { getUser };
