import { Action } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';

import * as appService from '../../../service/app/appService';
import { LOAD_APP_CONFIG } from '../../action-type/app/AppActionTypes';
import { LOAD_APP_CONFIG_FAILURE, LOAD_APP_CONFIG_SUCCESS } from '../../action/app/appAction';

const AppConfigEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    ofType(LOAD_APP_CONFIG),
    mergeMap(async () => {
      try {
        const response = await appService.getConfig();
        return LOAD_APP_CONFIG_SUCCESS(response.data);
      } catch (error) {
        console.error('Unable to retrieve app config');
        return LOAD_APP_CONFIG_FAILURE();
      }
    }),
  );

export default AppConfigEpic;
