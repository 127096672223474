import React from 'react';

import EnvironmentLabelProps from './EnvironmentLabelProps';

const EnvironmentLabel = ({ environmentName }: EnvironmentLabelProps) => {
  return (
    <div className="haae__Environemt-Label">
      <span data-test-id="environmentLabel">ithd-ms-teams-tabs-client-{environmentName}</span>
    </div>
  );
};

export default EnvironmentLabel;
