import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PkceChallenge, Token, User } from '../../../components/shared/model/user/UserTypes';
import UserState from './UserSliceTypes';

const initialState: UserState = {
  auth: {
    authUri: '',
    challenge: null,
  },
  ssoFailure: false,
  ssoToken: null,
  token: null,
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_PKCE_CHALLENGE: (state: UserState, action: PayloadAction<PkceChallenge>) => ({
      ...state,
      auth: { ...state.auth, challenge: action.payload },
    }),
    SET_SSO_TOKEN: (state: UserState, action: PayloadAction<string>) => ({
      ...state,
      ssoToken: action.payload,
    }),
    SET_SSO_FAILURE: (state: UserState, action: PayloadAction<boolean>) => ({
      ...state,
      ssoFailure: action.payload,
    }),
    GET_ACCESS_TOKEN_SUCCESS: (state: UserState, action: PayloadAction<Token>) => ({
      ...state,
      token: action.payload,
    }),
    GET_ACCESS_TOKEN_FAILURE: (state: UserState) => {
      return { ...state };
    },
    GET_AUTH_URI_SUCCESS: (state: UserState, action: PayloadAction<string>) => ({
      ...state,
      auth: { ...state.auth, authUri: action.payload },
    }),
    GET_AUTH_URI_FAILURE: (state: UserState) => ({
      ...state,
    }),
    GET_USER_SUCCESS: (state: UserState, action: PayloadAction<User>) => ({
      ...state,
      user: action.payload,
    }),
    GET_USER_FAILURE: (state: UserState) => ({
      ...state,
    }),
  },
});

export default userSlice;
