import * as microsoftTeams from '@microsoft/teams-js';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GET_ACCESS_TOKEN } from '../../../../redux/action/user/UserAction';
import { getToken } from '../../../../redux/selector/user/UserSelector';
import Loader from '../../../shared/loader/Loader';

const AuthStart = () => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  useEffect((): void => {
    microsoftTeams.initialize();
    if (!token) {
      const parsedParams = parse(window.location.search);
      const code = parsedParams['code'] as string;
      dispatch(GET_ACCESS_TOKEN({ code }));
    } else {
      microsoftTeams.authentication.notifySuccess(JSON.stringify(token));
    }
  }, [token, dispatch]);

  return <Loader isFullScreen message="Returning to app..." />;
};

export default AuthStart;
