import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import  { parseCountryCode } from '../../service/translate/locale';
import { store } from '../../redux/store/store';
import { getLanguage, getTeamsContext } from '../../redux/selector/app/appSelector';

const { LANGUAGES } = require('./languages');
const { NODE_ENV } = process.env;
const currentLanguage = getLanguage(store.getState());
const teamsLanguage = getTeamsContext(store.getState())
i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
    fallbackLng: parseCountryCode(store.getState().app.currentLanguage).value || 'en',
    preload: LANGUAGES.languages,
    initImmediate: false,
    lng: parseCountryCode(currentLanguage).value || parseCountryCode(teamsLanguage.locale).value,
    debug: NODE_ENV === 'development',
    whitelist: LANGUAGES.languages,
    interpolation: {
      escapeValue: false, 
    },
    react:{
      useSuspense:false
    }
  });

export default i18n;