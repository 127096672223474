import { createAction } from '@reduxjs/toolkit';

import * as UserActionTypes from '../../action-type/user/UserActionTypes';
import userSlice from '../../slice/user/UserSlice';
import { GetAccessTokenPayload } from './UserActionTypes';

const {
  SET_PKCE_CHALLENGE,
  SET_SSO_TOKEN,
  SET_SSO_FAILURE,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN_FAILURE,
  GET_AUTH_URI_SUCCESS,
  GET_AUTH_URI_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
} = userSlice.actions;

const EXCHANGE_SSO_TOKEN = createAction<string>(UserActionTypes.EXCHANGE_SSO_TOKEN);
const GET_ACCESS_TOKEN = createAction<GetAccessTokenPayload>(UserActionTypes.GET_ACCESS_TOKEN);
const GET_AUTH_URI = createAction(UserActionTypes.GET_AUTH_URL);
const GET_USER = createAction<String>(UserActionTypes.GET_USER_INFO);

export {
  EXCHANGE_SSO_TOKEN,
  SET_PKCE_CHALLENGE,
  SET_SSO_TOKEN,
  SET_SSO_FAILURE,
  GET_ACCESS_TOKEN,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_ACCESS_TOKEN_FAILURE,
  GET_AUTH_URI,
  GET_AUTH_URI_SUCCESS,
  GET_AUTH_URI_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
};
