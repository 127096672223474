import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import rootEpic from '../epic/root/RootEpic';
import { appReducer } from '../slice/app/appSlice';
import userSlice from '../slice/user/UserSlice';
import welcomeSlice from '../slice/welcome/WelcomeSlice';
import { getInitialStoreState, setCurrentStoreState } from '../util/ReduxUtil';

const epicMiddleware = createEpicMiddleware();

const initialState: any | undefined = getInitialStoreState();
export const store = configureStore({
  middleware: [epicMiddleware],
  reducer: {
    app: appReducer,
    user: userSlice.reducer,
    welcome: welcomeSlice.reducer,
  },
  preloadedState: initialState,
});

epicMiddleware.run(rootEpic);

store.subscribe(() => setCurrentStoreState(store));

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
