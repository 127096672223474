import { createAction } from '@reduxjs/toolkit';

import * as AppActionTypes from '../../action-type/app/AppActionTypes';
import { appSlice } from '../../slice/app/appSlice';

const { LOAD_APP_CONFIG_SUCCESS, LOAD_APP_CONFIG_FAILURE, SET_TEAMS_CONTEXT, SET_LANGUAGE } = appSlice.actions;

const LOAD_APP_CONFIG = createAction(AppActionTypes.LOAD_APP_CONFIG);

export { LOAD_APP_CONFIG, LOAD_APP_CONFIG_SUCCESS, LOAD_APP_CONFIG_FAILURE, SET_TEAMS_CONTEXT, SET_LANGUAGE };
