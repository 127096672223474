import { EnhancedStore } from '@reduxjs/toolkit';

import { SESSION_STORAGE_KEY_STORE } from '../../appConstants';
import { getItem, setItem } from '../../service/session-storage/SessionStorageService';
import { RootState } from '../store/store';

const getInitialStoreState = (): RootState | undefined => {
  const serializedState: string | null = getItem(SESSION_STORAGE_KEY_STORE);
  return serializedState ? JSON.parse(serializedState) : undefined;
};

const setCurrentStoreState = (store: EnhancedStore<RootState>): void =>
  setItem(SESSION_STORAGE_KEY_STORE, JSON.stringify(store.getState()));

export { getInitialStoreState, setCurrentStoreState };
