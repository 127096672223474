import { AnyAction } from '@reduxjs/toolkit';
import { ActionsObservable, Epic, StateObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';

import { WebsocketConnectionStatus } from '../../../service/websocket/WebSocketConnectionStatus';
import { WebsocketEventType } from '../../../service/websocket/WebSocketEventType';
import * as WebsocketService from '../../../service/websocket/WebsocketService';
import {
  WebsocketEventError,
  WebsocketEventQueueMetrics,
  WebsocketMessageQueueMetrics,
} from '../../../service/websocket/WebSocketServiceTypes';
import * as welcomeActionTypes from '../../action-type/welcome/WelcomeActionTypes';
import * as welcomeAction from '../../action/welcome/WelcomeAction';
import { RootState } from '../../store/store';

let websocketConnection: WebSocketSubject<WebsocketMessageQueueMetrics>;

const onWebsocketMessage$ = (websocketSubject: WebSocketSubject<WebsocketMessageQueueMetrics>) =>
  websocketSubject.pipe(
    map((message: WebsocketMessageQueueMetrics) => {
      if (message.eventType === WebsocketEventType.MESSAGE) {
        return welcomeAction.GET_QUEUE_METRICS_SUCCESS(message as WebsocketEventQueueMetrics);
      }
      return welcomeAction.GET_QUEUE_METRICS_FAILURE(message as WebsocketEventError);
    }),
    catchError(() => of(welcomeAction.SET_WEBSOCKET_CONNECTION_STATE(WebsocketConnectionStatus.ERROR))),
  );

const createWebsocketConnectionEpic: Epic<AnyAction, AnyAction, RootState> = (
  actions$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  actions$.pipe(
    ofType(welcomeActionTypes.CREATE_QUEUE_METRIC_CONNECTION),
    withLatestFrom(state$),
    switchMap(([_, state]: [AnyAction, RootState]) => {
      const {
        app: { config },
        user: { token },
      } = state;
      websocketConnection = WebsocketService.createWebsocketConnection<WebsocketMessageQueueMetrics>(
        config.tabsWebsocketUrl,
        token?.accessToken!,
      );
      return onWebsocketMessage$(websocketConnection);
    }),
    catchError(() => of(welcomeAction.SET_WEBSOCKET_CONNECTION_STATE(WebsocketConnectionStatus.ERROR))),
  );

const websocketDisconnectEpic: Epic<AnyAction, AnyAction, RootState> = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    ofType(welcomeActionTypes.QUEUE_METRIC_DISCONNECT),
    map(() => {
      websocketConnection.complete();
      websocketConnection.unsubscribe();
      return welcomeAction.SET_WEBSOCKET_CONNECTION_STATE(WebsocketConnectionStatus.DISCONNECTED);
    }),
  );

export { createWebsocketConnectionEpic, websocketDisconnectEpic };
