import React from 'react';
import { useSelector } from 'react-redux';

import { AppConfig } from '../../../model/app/app-config.model';
import { getAppConfig } from '../../../redux/selector/app/appSelector';
import EnvironmentLabel from '../environmentLabel/EnvironmentLabel';

const Environment = () => {
  const appConfig: AppConfig = useSelector(getAppConfig);
  if (appConfig.showEnvironmentLabel) {
    return <EnvironmentLabel environmentName={appConfig.environmentName} />;
  }

  return null;
};

export default Environment;
