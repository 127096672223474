import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GET_AUTH_URI } from '../../../../redux/action/user/UserAction';
import { getAuthUri } from '../../../../redux/selector/user/UserSelector';
import Loader from '../../../shared/loader/Loader';

const AuthStart = () => {
  const authUri = useSelector(getAuthUri);
  const dispatch = useDispatch();

  useEffect((): void => {
    if (!authUri) {
      dispatch(GET_AUTH_URI());
    } else {
      window.location.assign(authUri);
    }
  }, [authUri, dispatch]);

  return <Loader isFullScreen message="Redirecting to login..." />;
};

export default AuthStart;
