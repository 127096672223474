import {
  TeamsThemeStylesProps,
  ThemePrepared,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsTheme,
} from '@fluentui/react-northstar';

// App Routes
const MAIN_ROUTE_PAGE_AUTH_START = '/auth/start';
const MAIN_ROUTE_PAGE_AUTH_END = '/auth/end';
const MAIN_ROUTE_HELP = '/help';
const MAIN_ROUTE_WELCOME = '/welcome';
const REDIRECT_ROUTE = '/redirect';

// Tech Help App Tab Ids
const TECH_HELP_APP_TAB_ID_CHAT = 'conversations';

// Sesssion Storage key Prefix
const TECH_HELP_APP_SESSION_KEY_PREFIX = 'TECH_HELP_APP';
const SESSION_STORAGE_KEY_STORE = `${TECH_HELP_APP_SESSION_KEY_PREFIX}_STORE`;

// MS Teams themes
const MS_TEAMS_THEMES: { [key: string]: ThemePrepared<TeamsThemeStylesProps> } = {
  default: teamsTheme,
  dark: teamsDarkTheme,
  contrast: teamsHighContrastTheme,
};

export {
  MAIN_ROUTE_PAGE_AUTH_START,
  MAIN_ROUTE_PAGE_AUTH_END,
  MAIN_ROUTE_HELP,
  MAIN_ROUTE_WELCOME,
  REDIRECT_ROUTE,
  TECH_HELP_APP_TAB_ID_CHAT,
  SESSION_STORAGE_KEY_STORE,
  MS_TEAMS_THEMES,
};
