import { combineEpics } from 'redux-observable';

import AppConfigEpic from '../app-config/AppConfigEpic';
import * as userEpic from '../user/UserEpic';
import * as welcomeEpic from '../welcome/WelcomeEpic';

const rootEpic = combineEpics(
  AppConfigEpic,
  userEpic.exchangeSsoTokenEpic,
  userEpic.getAccessTokenEpic,
  userEpic.getAuthUriEpic,
  userEpic.getUserEpic,
  welcomeEpic.createWebsocketConnectionEpic,
  welcomeEpic.websocketDisconnectEpic,
);

export default rootEpic;
