const LANGUAGES = {
  languages: ['en', 'es', 'pt'],
  values: [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'pt', label: 'Portugués' },
  ],
};

export { LANGUAGES };
