import { AnyAction } from '@reduxjs/toolkit';
import pkceChallenge from 'pkce-challenge';
import { ActionsObservable, Epic, StateObservable, ofType } from 'redux-observable';
import { concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as authService from '../../../service/auth/AuthService';
import { GetAccessTokenRequest } from '../../../service/auth/AuthServiceTypes';
import * as userSerice from '../../../service/user/userService';
import * as userActionTypes from '../../action-type/user/UserActionTypes';
import {
  GET_ACCESS_TOKEN_FAILURE,
  GET_ACCESS_TOKEN_SUCCESS,
  GET_AUTH_URI_FAILURE,
  GET_AUTH_URI_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  SET_PKCE_CHALLENGE,
  SET_SSO_FAILURE,
} from '../../action/user/UserAction';
import { RootState } from '../../store/store';

const exchangeSsoTokenEpic: Epic<AnyAction, AnyAction, RootState> = (
  actions$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  actions$.pipe(
    ofType(userActionTypes.EXCHANGE_SSO_TOKEN),
    withLatestFrom(state$),
    mergeMap(async ([action, state]: [AnyAction, RootState]) => {
      const {
        app: {
          config: { tabsServiceUrl, tabsServiceApiKey },
        },
      } = state;
      const { payload: token } = action;
      try {
        const tokenResponse = await authService.exchangeSsoToken(tabsServiceUrl, tabsServiceApiKey, token);
        return GET_ACCESS_TOKEN_SUCCESS(tokenResponse);
      } catch (error) {
        return SET_SSO_FAILURE(true);
      }
    }),
  );

const getAccessTokenEpic: Epic<AnyAction, AnyAction, RootState> = (
  actions$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  actions$.pipe(
    ofType(userActionTypes.GET_ACCESS_TOKEN),
    withLatestFrom(state$),
    mergeMap(async ([action, state]: [AnyAction, RootState]) => {
      const {
        app: {
          config: { tabsServiceUrl, tabsServiceApiKey },
        },
        user: { auth },
      } = state;
      const { payload } = action;
      const params: GetAccessTokenRequest = {
        code: payload.code,
        codeVerifier: auth.challenge?.code_verifier!,
      };
      try {
        const tokenResponse = await authService.getAccessToken(tabsServiceUrl, tabsServiceApiKey, params);
        return GET_ACCESS_TOKEN_SUCCESS(tokenResponse);
      } catch (error) {
        return GET_ACCESS_TOKEN_FAILURE();
      }
    }),
  );

const getAuthUriEpic: Epic<AnyAction, AnyAction> = (
  actions$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  actions$.pipe(
    ofType(userActionTypes.GET_AUTH_URL),
    withLatestFrom(state$),
    mergeMap(async ([_, state]: [AnyAction, RootState]) => {
      const {
        app: {
          config: { tabsServiceUrl, tabsServiceApiKey },
        },
      } = state;
      try {
        const { authURI } = await authService.getAuthUrl(tabsServiceUrl, tabsServiceApiKey);
        const challenge = pkceChallenge(128);
        const challengeParam = new URLSearchParams();
        challengeParam.set('code_challenge', challenge.code_challenge);

        return [SET_PKCE_CHALLENGE(challenge), GET_AUTH_URI_SUCCESS(`${authURI}&${challengeParam.toString()}`)];
      } catch (error) {
        return [GET_AUTH_URI_FAILURE()];
      }
    }),
    concatMap((actions) => actions),
  );

const getUserEpic: Epic<AnyAction, AnyAction> = (
  actions$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  actions$.pipe(
    ofType(userActionTypes.GET_USER_INFO),
    withLatestFrom(state$),
    mergeMap(async ([action, state]: [AnyAction, RootState]) => {
      const {
        app: {
          config: { tabsServiceUrl, tabsServiceApiKey },
        },
        user: { token },
      } = state;
      try {
        const user = await userSerice.getUser(tabsServiceUrl, tabsServiceApiKey, token?.accessToken!, action.payload);
        return GET_USER_SUCCESS(user);
      } catch (error) {
        return GET_USER_FAILURE();
      }
    }),
  );

export { exchangeSsoTokenEpic, getAccessTokenEpic, getAuthUriEpic, getUserEpic };
