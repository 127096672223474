import { createSelector } from 'reselect';

import { AppState } from '../../model/app-state.model';
import { RootState } from '../../store/store';

export const selectAppState = (state: RootState) => state.app;

export const getAppConfig = createSelector([selectAppState], (appState: AppState) => appState.config);
export const isAppReady = createSelector([selectAppState], (appState: AppState) => appState.appReady);
export const getTeamsContext = createSelector([selectAppState], (appState: AppState) => appState.teamsContext);
export const getLanguage = createSelector([selectAppState], (appState: AppState) => appState.currentLanguage);
