import * as microsoftTeams from '@microsoft/teams-js';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppConfig } from '../../../model/app/app-config.model';
import { AppState } from '../../model/app-state.model';

const initialState: AppState = {
  appReady: false,
  config: {
    environmentName: '',
    showEnvironmentLabel: false,
    clientId: '',
    teamsAppId: '',
    teamsBotId: '',
    heapAppId: '',
    tabsServiceUrl: '',
    tabsServiceApiKey: '',
    tabsWebsocketUrl: '',
  },
  teamsContext: null,
  currentLanguage: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    LOAD_APP_CONFIG_SUCCESS: (state: AppState, action: PayloadAction<AppConfig>) => {
      return { ...state, appReady: true, config: action.payload };
    },
    LOAD_APP_CONFIG_FAILURE: (state: AppState) => {
      return { ...state };
    },
    SET_TEAMS_CONTEXT: (state: AppState, action: PayloadAction<microsoftTeams.Context>) => {
      return { ...state, teamsContext: action.payload };
    },
    SET_LANGUAGE: (state: AppState, action: PayloadAction<string>) => {
      return { ...state, currentLanguage: action.payload };
    },
  },
});

export const appReducer = appSlice.reducer;
