
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as appActions from '../../../redux/action/app/appAction'; 
import { useDispatch, useSelector } from 'react-redux';
import { Select, SelectOption } from '@lmig/lmds-react/';
import { getLanguage } from '../../../redux/selector/app/appSelector';

const { LANGUAGES } = require('../../../config/language/languages')
const { values:languages } = LANGUAGES;

const SelectLanguage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Language is updated and user preference is saved
  const HandlerChangeLang = (event:any) => {
    const lang = event.currentTarget.value;
    dispatch(appActions.SET_LANGUAGE(lang))
    i18n.changeLanguage(lang)
}

return(
  <div className="haae_Form_language-select" > 
    <Select
      id="select--default"
      labelVisual = {t('Select.selectLanguage.title')}
      onChange = {HandlerChangeLang}
      defaultValue = {useSelector(getLanguage)}
    >
      {languages.map( (el:any) => { return (<SelectOption key = {el.value} value = {el.value}>{el.label}</SelectOption>)})}
    </Select>
</div>
);
}

export default SelectLanguage;
