import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  MAIN_ROUTE_HELP,
  MAIN_ROUTE_PAGE_AUTH_END,
  MAIN_ROUTE_PAGE_AUTH_START,
  MAIN_ROUTE_WELCOME,
  REDIRECT_ROUTE
} from '../../../appConstants';
import SuspenseWrapper from '../../shared/suspense-wrapper/SuspenseWrapper';
import AuthEnd from '../pages/auth-end/AuthEnd';
import AuthStart from '../pages/auth-start/AuthStart';

const HelpTab = lazy(() => import('../../help/tab/HelpTab'));
const WelcomeTab = lazy(() => import('../../welcome/tab/welcome/WelcomeTab'));
const Redirect = lazy(() => import('../../redirect/pages/Redirect'));

const Main = () => {
  return (
    <>
      <Switch>
        <Route exact path={MAIN_ROUTE_PAGE_AUTH_START} component={AuthStart} />
        <Route exact path={MAIN_ROUTE_PAGE_AUTH_END} component={AuthEnd} />
        <Route path={REDIRECT_ROUTE}
          render={() => (
            <SuspenseWrapper loadingMessage="Loading">
              <Redirect />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={MAIN_ROUTE_WELCOME}
          render={() => (
            <SuspenseWrapper loadingMessage="Loading...">
              <WelcomeTab />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={MAIN_ROUTE_HELP}
          render={() => (
            <SuspenseWrapper loadingMessage="Loading Connection Issues...">
              <HelpTab />
            </SuspenseWrapper>
          )}
        />
      </Switch>
    </>
  );
};

export default Main;
