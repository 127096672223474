import axios from 'axios';

import { Token } from '../../components/shared/model/user/UserTypes';
import axiosRequestErrorHandler from '../utils/axioxRequestUtils';
import { GetAccessTokenRequest, GetAuthUriResponse } from './AuthServiceTypes';

const exchangeSsoToken = async (serviceUrl: string, apiKey: string, token: string): Promise<Token> => {
  try {
    const response = await axios.post<Token>(`${serviceUrl}/auth/sso`, null, {
      headers: {
            'x-api-key': apiKey,
            Authorization: `Bearer ${token}`,
          }
    });

    return response.data;
  } catch (error) {
    return axiosRequestErrorHandler(error);
  }
};

const getAccessToken = async (serviceUrl: string, apiKey: string, params: GetAccessTokenRequest): Promise<Token> => {
  const { code, codeVerifier } = params;
  try {
    const response = await axios.post<Token>(
      `${serviceUrl}/auth`,
      {
        code,
        codeVerifier,
      },
      {
        headers: {
          'x-api-key': apiKey,
        },
      },
    );

    return response.data;
  } catch (error) {
    return axiosRequestErrorHandler(error);
  }
};

const getAuthUrl = async (serviceUrl: string, apiKey: string): Promise<GetAuthUriResponse> => {
  try {
    const response = await axios.get<GetAuthUriResponse>(`${serviceUrl}/auth`, {
      headers: {
        'x-api-key': apiKey,
      },
    });

    return response.data;
  } catch (error) {
    return axiosRequestErrorHandler(error);
  }
};

export { exchangeSsoToken, getAccessToken, getAuthUrl };
