import { Loader as FluentLoader } from '@fluentui/react-northstar';
import React from 'react';

import LoaderProps from './LoaderProps';

const Loader = ({ isFullScreen, message }: LoaderProps) => {
  if (!isFullScreen) {
    return (
      <div className="haae__Loader">
        <FluentLoader inline label={message} labelPosition="end" size="small" />
      </div>
    );
  }

  return (
    <div data-test-id="loaderFullscreen" className="haae__Loader haae__Loader--full-screen">
      <div className="haae__Loader__container">
        <FluentLoader label={message} labelPosition="below" />
      </div>
    </div>
  );
};

export default Loader;
