import React, { Suspense } from 'react';

import Loader from '../loader/Loader';
import SuspenseWrapperProps from './SuspenseWrapperProps';

const SuspenseWrapper: React.FunctionComponent<SuspenseWrapperProps> = ({ children, loadingMessage }) => (
  <Suspense fallback={<Loader isFullScreen message={loadingMessage} />}>{children}</Suspense>
);

export default SuspenseWrapper;
