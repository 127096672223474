import { createAction } from '@reduxjs/toolkit';

import * as WelcomeActionTypes from '../../action-type/welcome/WelcomeActionTypes';
import welcomeSlice from '../../slice/welcome/WelcomeSlice';

const { GET_QUEUE_METRICS_FAILURE, GET_QUEUE_METRICS_SUCCESS, SET_WEBSOCKET_CONNECTION_STATE } = welcomeSlice.actions;

const CREATE_QUEUE_METRIC_CONNECTION = createAction(WelcomeActionTypes.CREATE_QUEUE_METRIC_CONNECTION);
const QUEUE_METRIC_DISCONNECT = createAction(WelcomeActionTypes.QUEUE_METRIC_DISCONNECT);

export {
  CREATE_QUEUE_METRIC_CONNECTION,
  GET_QUEUE_METRICS_FAILURE,
  GET_QUEUE_METRICS_SUCCESS,
  QUEUE_METRIC_DISCONNECT,
  SET_WEBSOCKET_CONNECTION_STATE,
};
