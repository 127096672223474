import './App.css';

import { Provider } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MS_TEAMS_THEMES } from './appConstants';
import Environment from './components/core/environment/Environment';
import Footer from './components/core/footer/Footer';
import Main from './components/core/main/Main';
import Loader from './components/shared/loader/Loader';
import * as appActions from './redux/action/app/appAction';
import { AppState } from './redux/model/app-state.model';
import { getLanguage, selectAppState } from './redux/selector/app/appSelector';
import SelectLanguage from './components/core/language-select/SelectLanguage';
import i18n from './config/language/i18n';
import { store } from './redux/store/store';

function App() {
  const dispatch = useDispatch();

  const {
    appReady,
    config: { heapAppId },
  }: AppState = useSelector(selectAppState);
  const [currentTheme, setCurrentTheme] = useState<string>();

  useEffect(() => {
    if (heapAppId) {
      (window as any).heap.load(heapAppId, {
        secureCookie: true,
      });
    } else {
      dispatch(appActions.LOAD_APP_CONFIG());
    }

    microsoftTeams.initialize();
    microsoftTeams.getContext((context: microsoftTeams.Context) => {
      dispatch(appActions.SET_TEAMS_CONTEXT(context));
      setCurrentTheme(context.theme || 'default');
      i18n.changeLanguage(getLanguage(store.getState()))
    });

    microsoftTeams.registerOnThemeChangeHandler((theme: string) => setCurrentTheme(theme));
  }, [dispatch, heapAppId]);
  const teamsTheme = currentTheme ? MS_TEAMS_THEMES[currentTheme] : MS_TEAMS_THEMES['default'];
  const app = !appReady ? (
    <Loader isFullScreen message="Loading..." />
  ) : (
    <div data-test-id="appStage" className="haae__Stage">
      <Main />
      <Footer />
      <SelectLanguage />
      <Environment />
    </div>
  );

  return <>{teamsTheme && <Provider theme={teamsTheme}>{app}</Provider>}</>;
}

export default App;
