import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { WebsocketConnectionStatus } from '../../../service/websocket/WebSocketConnectionStatus';
import { WebsocketEventError, WebsocketEventQueueMetrics } from '../../../service/websocket/WebSocketServiceTypes';
import { WelcomeState } from './WelcomeSliceTypes';

const initialState: WelcomeState = {
  queueMetrics: null,
  websocketConnectionState: WebsocketConnectionStatus.DISCONNECTED,
};

const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    GET_QUEUE_METRICS_SUCCESS: (state: WelcomeState, action: PayloadAction<WebsocketEventQueueMetrics>) => ({
      ...state,
      queueMetrics: action.payload,
      websocketConnectionState: WebsocketConnectionStatus.CONNECTED,
    }),
    GET_QUEUE_METRICS_FAILURE: (state: WelcomeState, action: PayloadAction<WebsocketEventError>) => ({
      ...state,
    }),
    SET_WEBSOCKET_CONNECTION_STATE: (state: WelcomeState, action: PayloadAction<WebsocketConnectionStatus>) => ({
      ...state,
      websocketConnectionState: action.payload,
    }),
  },
});

export default welcomeSlice;
