import { createSelector } from '@reduxjs/toolkit';

import UserState from '../../slice/user/UserSliceTypes';
import { RootState } from '../../store/store';

const selectUserState = (state: RootState) => state.user;

const getAuthUri = createSelector([selectUserState], (state: UserState) => state.auth.authUri);
const getPkceChallenge = createSelector([selectUserState], (state: UserState) => state.auth.challenge);
const getSsoToken = createSelector([selectUserState], (state: UserState) => state.ssoToken);
const getSsoFailureState = createSelector([selectUserState], (state: UserState) => state.ssoFailure);
const getToken = createSelector([selectUserState], (state: UserState) => state.token);
const getUser = createSelector([selectUserState], (state: UserState) => state.user);

export { getAuthUri, getPkceChallenge, getToken, getSsoToken, getSsoFailureState, getUser };
