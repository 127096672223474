import { Disclaimer } from '@lmig/lmds-react';
import React from 'react';

const Footer = () => (
  <footer className="haae__Footer">
    <div className="haae__Stage__inner">
      <Disclaimer>
        <p className="haae__Footer__diclaimer">
          &#169; {new Date().getFullYear()} Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116
        </p>
      </Disclaimer>
    </div>
  </footer>
);

export default Footer;
